/*******************************
         Site Overrides
*******************************/

/* Custom Button for Sidebar */
.ui.basic.theme.buttons.sidebar-btn .button,
.ui.basic.theme.button.sidebar-btn {
	margin: 0; padding: 0;
	font-weight: @semiBold;
  box-shadow: none !important;
	color: @grey !important;
	a {
		display: block; min-height: 2.25em;
		color: inherit; text-decoration: none;
		display: flex; align-items: center;
	}
}
.ui.basic.theme.buttons.sidebar-btn .button:hover,
.ui.basic.theme.button.sidebar-btn:hover {
	font-weight: @bold;
  box-shadow: none !important;
	color: @themeColorHover !important;
	a {
		display: block; min-height: 2.25em;
		color: inherit; text-decoration: none;
		display: flex; align-items: center;
	}
}
.ui.basic.theme.buttons.sidebar-btn .button:focus,
.ui.basic.theme.button.sidebar-btn:focus {
	font-weight: @bold;
	box-shadow: none !important;
	color: @lightGrey !important;
	a {
		display: block; min-height: 2.25em;
		color: inherit; text-decoration: none;
		display: flex; align-items: center;
	}
}
.ui.basic.theme.buttons.sidebar-btn .active.button,
.ui.basic.theme.active.sidebar-btn.button {
	font-weight: @bold;
  box-shadow: none !important;
	color: @themeColor !important;
	background-color: rgba(255, 255, 255, 0) !important;
	a {
		display: block; min-height: 2.25em;
		color: inherit; text-decoration: none;
		display: flex; align-items: center;
	}
}
.ui.basic.theme.buttons.sidebar-btn .button:active,
.ui.basic.theme.button.sidebar-btn:active {
	font-weight: @bold;
  box-shadow: none !important;
	color: @themeColorDown !important;
	background-color: rgba(255, 255, 255, 0) !important;
	a {
		display: block; min-height: 2.25em;
		color: inherit; text-decoration: none;
		display: flex; align-items: center;
	}
}

.ui.buttons .disabled.button,
.ui.disabled.button,
.ui.button:disabled,
.ui.disabled.button:hover,
.ui.disabled.active.button {
  opacity: @disabledButtonOpacity !important;
}


/*--------------------*/
/*--- Button Theme ---*/
/*--------------------*/
.ui.theme.buttons .button,
.ui.theme.button {
  background-color: @themeColor;
  color: @themeTextColor;
  text-shadow: @themeTextShadow;
  background-image: @coloredBackgroundImage;
}
.ui.theme.button {
  box-shadow: @coloredBoxShadow;
}
.ui.theme.buttons .button:hover,
.ui.theme.button:hover {
  background-color: @themeColorHover;
  color: @themeTextColor;
  text-shadow: @themeTextShadow;
}
.ui.theme.buttons .button:focus,
.ui.theme.button:focus {
  background-color: @themeColorFocus;
  color: @themeTextColor;
  text-shadow: @themeTextShadow;
}
.ui.theme.buttons .button:active,
.ui.theme.button:active {
  background-color: @themeColorDown;
  color: @themeTextColor;
  text-shadow: @themeTextShadow;
}
.ui.theme.buttons .active.button,
.ui.theme.buttons .active.button:active,
.ui.theme.active.button,
.ui.theme.button .active.button:active {
  background-color: @themeColorActive;
  color: @themeTextColor;
  text-shadow: @themeTextShadow;
}


/*--------------------*/
/*--- Button Basic Theme ---*/
/*--------------------*/
.ui.basic.theme.buttons .button,
.ui.basic.theme.button {
  box-shadow: 0px 0px 0px @basicBorderSize @themeColor inset !important;
  color: @themeColor !important;
}
.ui.basic.theme.buttons .button:hover,
.ui.basic.theme.button:hover {
  background: transparent !important;
  box-shadow: 0px 0px 0px @basicColoredBorderSize @themeColorHover inset !important;
  color: @themeColorHover !important;
}
.ui.basic.theme.buttons .button:focus,
.ui.basic.theme.button:focus {
  background: transparent !important;
  box-shadow: 0px 0px 0px @basicColoredBorderSize @themeColorFocus inset !important;
  color: @themeColorHover !important;
}
.ui.basic.theme.buttons .active.button,
.ui.basic.theme.active.button {
  background: transparent !important;
  box-shadow: 0px 0px 0px @basicColoredBorderSize @themeColorActive inset !important;
  color: @themeColorDown !important;
}
.ui.basic.theme.buttons .button:active,
.ui.basic.theme.button:active {
  box-shadow: 0px 0px 0px @basicColoredBorderSize @themeColorDown inset !important;
  color: @themeColorDown !important;
}
.ui.buttons:not(.vertical) > .basic.theme.button:not(:first-child) {
  margin-left: -@basicColoredBorderSize;
}
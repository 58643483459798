/*******************************
         Site Overrides
*******************************/

/*--------------------
       Disabled
---------------------*/

.ui.form .disabled.field input{
    background: @offWhite;
}

/*--------------------
        Labels
---------------------*/
.ui.form.theme .field > label {
  color: @themeColor;
}

/*--------------------
        Focus
---------------------*/

.ui.form.theme input:not([type]):focus,
.ui.form.theme input[type="date"]:focus,
.ui.form.theme input[type="datetime-local"]:focus,
.ui.form.theme input[type="email"]:focus,
.ui.form.theme input[type="number"]:focus,
.ui.form.theme input[type="password"]:focus,
.ui.form.theme input[type="search"]:focus,
.ui.form.theme input[type="tel"]:focus,
.ui.form.theme input[type="time"]:focus,
.ui.form.theme input[type="text"]:focus,
.ui.form.theme input[type="file"]:focus,
.ui.form.theme input[type="url"]:focus {
  border-color: @themeColor;
}
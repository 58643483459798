/*******************************
         Site Overrides
*******************************/

/*******************************
             Icon
*******************************/

@font-face {
  font-family: @impulsFontFamily;
  src: @impulsSrc;
  font-style: normal;
  font-weight: @normal;
  font-variant: normal;
  text-decoration: inherit;
  text-transform: none;
}

i.icon.imp { font-family: @impulsFontFamily !important; }

.imp-impuls:before { content: "\e902"; }
.imp-guru:before { content: "\e900"; }
.imp-peserta:before { content: "\e90c"; }
.imp-ppdb:before { content: "\e90e"; }
.imp-kelompok:before { content: "\e906"; }
.imp-kelas-pengaturan:before { content: "\e90f"; }
.imp-kelas:before { content: "\e905"; }
.imp-jadwal:before { content: "\e903"; }
.imp-waktu:before { content: "\e904"; }
.imp-ikhtisar:before { content: "\e901"; }
.imp-nilai:before { content: "\e909"; }
.imp-kurikulum:before { content: "\e907"; }
.imp-prestasi:before { content: "\e910"; }
.imp-ekskul:before { content: "\e915"; }
.imp-pengumuman:before { content: "\e919"; }
.imp-peminatan:before { content: "\e917"; }
.imp-tata-tertib:before { content: "\e911"; }
.imp-konseling:before { content: "\e912"; }
.imp-administrasi:before { content: "\e913"; }
.imp-soal:before { content: "\e90d"; }
.imp-paket-soal:before { content: "\e90a"; }
.imp-kelompok-paket-soal:before { content: "\e908"; }
.imp-rapor:before { content: "\e914"; }
.imp-keuangan:before { content: "\e916"; }
.imp-pengaturan:before { content: "\e90b"; }
.imp-profil:before { content: "\e918"; }
.imp-menu-buka:before { content: "\e91d"; } 
.imp-menu-tutup:before { content: "\e91e"; }


/* Icon color theme */
i.themeColor.imp.icon { 
  color: @themeColor !important; 
}

i.inverted.themeColor.imp.icon { 
  color: @themeColor !important; 
}

i.inverted.bordered.icon.imp.theme,
i.inverted.circular.icon.imp.theme {
  background-color: @themeColor !important; 
  color: @white !important;
}